import React, { useState } from "react";
import ResultsContainer from "../../components/ResultsContainer/ResultsContainer";

const ResultSection = ({ results }) => {

    const [ allResults, setAllResults ] = useState(false)
    const [ archivedResults, setArchivedResults ] = useState(false)
    const [ buttonContent, setButtonContent ] = useState('Show more results')
    const [ archivedButtonContent, setArchivedButtonContent ] = useState('Show archived results')

    const activeResultItems = results.slice(0,3)
    const hiddenReultsItems = results.slice(3, 12)
    const archivedResultsItem = results.slice(12)

    const handleResults = () => {
        setAllResults(!allResults)
        if(buttonContent === 'Show more results'){
            setButtonContent('Hide more results')
        } else {
            setButtonContent('Show more results')
        }
    }

    const handleArchived = () => {
        setArchivedResults(!archivedResults)
        if(archivedButtonContent === 'Show archived results'){
            setArchivedButtonContent('Hide archived results')
        } else {
            setArchivedButtonContent('Show archived results')
        }
    }

    return (

        <>
            <ResultsContainer 
                results={activeResultItems}
                buttonContent={buttonContent}
                handleFunction={handleResults}
            />
            {allResults === true &&
            <>
                <ResultsContainer 
                    results={hiddenReultsItems}
                    buttonContent={archivedButtonContent}
                    handleFunction={handleArchived}
                />
                {archivedResults === true &&
                    <ResultsContainer 
                        results={archivedResultsItem}
                    />
                }
            </>   
            }
        </>
    )
}


export default ResultSection
import React from "react";
import ResultItemMetric from "./ResultItemMetric";

const ResultItemData = ({ resultData }) => {


    return (

        <>
        {resultData.map(item => (
            <ResultItemMetric 
                title={item.title}
                trend_title={item.trend_title}
                trend_number={item.trend_number}
            />
        ))}
        </>
    )
}

export default ResultItemData
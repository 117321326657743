import React from "react";
import ResultItem from "../ResultItem/ResultItem";

const ResultsContainer = ({ results, buttonContent, handleFunction }) => {

    return (

        <>
            {results.map(item=>(
                <ResultItem item={item} />
            ))}
            {buttonContent &&
                <div 
                    className="btn btn-secondary m-3" 
                    onClick={handleFunction} 
                    onKeyDown={handleFunction} 
                    role="button" 
                    tabIndex="0"
                >
                    {buttonContent}
                </div>
            }
        </>
    )
}


export default ResultsContainer
import { graphql, useStaticQuery } from "gatsby";

export const useExchangesData = () => {
    const data = useStaticQuery(graphql`
        query AllExchangesDataQuery {
            allPrismicExchanges {
                nodes {
                  url
                  id
                  lang
                  data {
                    name
                    url
                    icon {
                      url
                      alt
                      gatsbyImageData
                    }
                  }
                }
              }
        }
    `)

    const exchangesData = data.allPrismicExchanges.nodes

    return exchangesData


}
import React from "react";
import { Image } from "../components/Image/Image";
import PrimaryButton from "../components/PrimaryButton/Primary Button";

const HeroSlice = ({ slice }) => {
 
    const contentData = slice.primary

    return (
        <section id="home" className="section hero crypto-hero" >
            <div className="layout-container">
                <div className="row">
                    <div className="col-lg-6 left-part">
                        <h1 className="section-title">{contentData.header}</h1>
                        <h4 className="section-content">{contentData.content}</h4>
                        <p className="section-content">{contentData.description}</p>
                        <div className="btn-container">
                            <PrimaryButton btnText={contentData.kicker} btnLink={'https://app.cryptobasescanner.com/sign_in'}/>
                            <a  className={"btn btn-secondary m-3 "} href={'https://www.altrady.com/academy/how-to-use-crypto-base-scanner'}>{contentData.btn_text}</a>
                        </div>
                    </div>
                    <div className="col-lg-6 right-part">
                        <Image 
                        src={contentData.img} 
                        alt={contentData.alt} 
                        loading={"eager"}
                        fadeIn={false} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroSlice
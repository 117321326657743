import React from "react";
import PricingItem from "../PricingItem/PricingItem";
import PrimaryButton from "../PrimaryButton/Primary Button";


const PricingContainer = ({ pricingItems, contentData }) => {


    return (
        <>
        <div className="pricingContainer">
            {pricingItems.map((item, idx) => (
                <PricingItem item={item} />
                ))}
        </div>
        <div className="pricingControls mt-5">
            <PrimaryButton btnText={contentData.btn_text} btnLink={contentData.btn_link} btnCls={"mb-4"}/>
            <p>{contentData.section_footer}</p>
        </div>
        </>
    )
} 


export default PricingContainer
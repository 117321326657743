import React from "react";
import Seo from "../components/Seo/Seo";
import Layout from "../layouts/Layout";
import { graphql } from "gatsby";
import { SliceZone } from "../components/SliceZone/SliceZone";

const homepage = ({ data }) => {

    if (!data) return null

    const pageContent = data.prismicHomepage
    const page = pageContent.data || {}

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords

    return (
        <Layout>  
            <Seo
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            />
            <SliceZone slices={page.body}/>
        </Layout>
    )
}

export const query = graphql`
query homePageQuery($id: String) {
  prismicHomepage(id: {eq: $id}) {
    id
    lang
    uid
    url
    data {
      meta_title
      meta_keywords
      meta_description
      flag_page
      body {
        ... on PrismicHomepageDataBodyTradingSoftwareContent {
          id
          slice_type
          slice_label
          primary {
            title
            subtitle
            heading
            content
          }
        }
        ... on PrismicHomepageDataBodyResultsSlice {
          id
          slice_type
          slice_label
          primary {
            title
            content
          }
          items {
            link
            month
            signals
            signals_trend
            win_rate
            win_rate1
            win_rate_trend
            win_rate_trend1
            date
          }
        }
        ... on PrismicHomepageDataBodyPricingSection {
          id
          slice_type
          slice_label
          primary {
            title
            section_footer
            content
            btn_text
            btn_link
          }
          items {
            time
            secondary_price
            saving
            promo_tag
            price
          }
        }
        ... on PrismicHomepageDataBodyIntegrations {
          id
          slice_type
          slice_label
          primary {
            title
            content
            btn
            altrady_item_title
            altrady_item_exchanges
            altrady_item_disclaimer
          }
          items {
            title
            link
            header
            exchanges
            disclaimer
            icon {
              url
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicHomepageDataBodyHeroSection {
          id
          slice_type
          slice_label
          primary {
            kicker
            header
            description
            content
            btn_text
            img {
              url
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicHomepageDataBodyFaqSection {
          id
          slice_type
          slice_label
          primary {
            title
            content
          }
          items {
            title
            content
          }
        }
      }
    }
  }
}


`;


export default homepage
import React from "react";
import { Image } from "../Image/Image";
import { starEmoji } from "../../utils/imgLoader";

const PricingItem = ({ item }) => {

    return (

        <div className="pricingItem">
            <div className="pricingItem__wrapper">
                <h5 className="pricingItem-title">{item.time}</h5>
                <h2 className="pricingItem-price">{item.price}</h2>
                <p className="pricingItem-currency">{item.secondary_price}</p>
            </div>
            <div className="promoTag">
                <p>{item.promo_tag}</p>
                <Image 
                src={starEmoji}
                loading={"eager"}
                fadeIn={false} 
                className="emoji"/>
            </div>
            <div>
                <p className="savings">{item.saving}</p>
            </div>
        </div>

    )
}

export default PricingItem
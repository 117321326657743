import React from "react";
import IntegrationItem from "../IntegrationItem/IntegrationItem";

const IntegrationContainer = ({ integrations, contentData }) => {

    return (
        <div className="integrationContainer">
            {integrations.map(item => (
                <IntegrationItem item={item} content={contentData}/>
            ))}
        </div>
    )
}


export default IntegrationContainer
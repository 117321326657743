import React from "react";
import LandingPageButton from "../LandingPageButton/LandingPageButton";
import ResultItemData from "./ResultItemData";

const ResultItem = ({ item }) => {

    const resultData = [
        {
            title: 'Total Signals',
            trend_title: item.signals,
            trend_number: item.signals_trend,
        },
        {
            title: '+1% Win Rate',
            trend_title: `${item.win_rate}%`,
            trend_number: `${item.win_rate_trend}%`,
        },
        {
            title: '+3 % Win Rate',
            trend_title: `${item.win_rate1}%`,
            trend_number: `${item.win_rate_trend1}%`,
        },
    ]
    
    return (
        <div className="resultItem">
            <div className="resultItem__header">
                <h3 className="resultItem__heading">{item.month}</h3>
            </div>
            <div className="resultItem__wrapper">
                <ResultItemData resultData={resultData} />
                <LandingPageButton href={item.link} text={'Report'}/>
            </div>
        </div>
    )
}

export default ResultItem
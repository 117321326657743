import React from "react";
import AltradyItemHeader from "./AltradyItemHeader";

const AltradyIntegrationsItem = ({ content }) => {

    const btn = content.btn
    const title = content.altrady_item_title
    const supportedExchanges = content.altrady_item_exchanges
    const disclaimer = content.altrady_item_disclaimer

    return (

        <div className="integrationItem integrationItem--altrady">
            <div className="">
                <AltradyItemHeader btn={btn}/>
                <div className="integrationItem__exchanges integrationItem__exchanges--altrady">
                    <h5 className="integrationItem__exchanges-title">{title}</h5>
                    <p className="integrationItem__exchanges-content integrationItem__exchanges-content--altrady">{supportedExchanges}</p>
                    <p className="integrationItem__exchanges-disclaimer">{disclaimer}</p>
                </div>
            </div>
        </div>
    )
}

export default AltradyIntegrationsItem
import React from "react";
import { toolTip } from "../../utils/imgLoader";
import { Image } from "../Image/Image";

const LabelWrap = ({ title }) => {

    return (

        <div className="label-wrap">
            <div className="label">{title}</div>
            <div className="icon">
                <Image 
                    src={toolTip}
                    loading={"eager"}
                    fadeIn={false} 
                />
            </div>
        </div>
    )
}


export default LabelWrap
import React from "react";
import LandingPageButton from "../LandingPageButton/LandingPageButton"
import { AltradyLogo } from "../../utils/imgLoader";
import { Image } from "../Image/Image";



const AltradyItemHeader = ({ btn }) => {

    return (
        <div className="integrationItem--altrady__header">
                <Image 
                    src={AltradyLogo} 
                    alt={'Altrady Logo'} 
                    loading={"eager"}
                    fadeIn={false} 
                    className="integrationItem__header-img--altrady"
                />
                <LandingPageButton href={'https://www.altrady.com/'} text={btn} />
        </div>
    )
}


export default AltradyItemHeader
import React from "react";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import AltradyIntegrationsItem from "../../components/AltradyIntegrationItem/AltradyIntegrationItem";
import IntegrationContainer from "../../components/IntegrationsContainer/IntegrationContainer";

const IntegrationsSection = ({ content, integrations }) => {

    return (

        <>
            <SectionHeader title={content.title} content={content.content}/>
            <AltradyIntegrationsItem content={content}/>
            <IntegrationContainer integrations={integrations} contentData={content.btn}/>
        </>
    )
}



export default IntegrationsSection
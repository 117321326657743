import React from "react"
import HeroSlice from "../../slices/HeroSlice"
import ResultSlice from "../../slices/ResultsSlice"
import IntegrationSlice from "../../slices/IntegrationsSlice"
import FaqSlice from "../../slices/FaqSlice"
import ExchangesSlice from "../../slices/EschangesSlice"
import PricingSlice from "../../slices/PricingSlice"

export const SliceZone = ({ slices }) => {

    const sliceComponents = {
        hero_section: HeroSlice,
        trading_software_content: ExchangesSlice,
        results_slice: ResultSlice,
        integrations: IntegrationSlice,
        faq_section: FaqSlice,
        pricing_section: PricingSlice,
    }

    return slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`} index={index} />
        }
        return null
    })
}


import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import IntegrationsSection from "../sections/IntegrationsSection/IntegrationsSection";

const IntegrationSlice = ({ slice }) => {

    const contentData = slice.primary
    const integrationItems = slice.items

    return (

        <SectionLayout id="integrations" sectionName="integrations" containerName="integrationsWrapper">
            <IntegrationsSection 
                content={contentData}
                integrations={integrationItems}
            />
        </SectionLayout>
    )
}

export default IntegrationSlice
import React from "react";
import { Image } from "../Image/Image";

const ExchangeItem = ({ item }) => {


    return (

        <div className="feature-item" >
            <Image 
                alt={item.data.name} 
                src={item.data.icon} 
                className="feature-item__image"
                loading={"eager"}
                fadeIn={false} 
            />
            <div className="feature-item__content-wrapper">
                <h5 className="feature-item__title">{item.data.name}</h5>
            </div>
        </div>

    )
}


export default ExchangeItem
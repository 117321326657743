import React from "react";
import LabelWrap from "./LabelWrap";
import MetricWrap from "./MetricWrap";

const ResultItemMetric = ({ title, trend_title, trend_number}) => {

    return (
        <div className="resultItem__wrapper-metric">
            <LabelWrap title={title} />
            <MetricWrap trendNumber={trend_number} trendTitle={trend_title}/>
        </div>
    )
}

export default ResultItemMetric
import React from "react";
import FaqComponent from "../components/FaqComponent/FaqComponent";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import SectionLayout from "../layouts/SectionLayout";

const FaqSlice = ({ slice }) => {

    const contentData = slice.primary
    const questions = slice.items

    return (

        <SectionLayout sectionName="faq" id="faq">
            <SectionHeader  title={contentData.title} content={contentData.content}/>
            <FaqComponent questions={questions} />
        </SectionLayout>
    )
}

export default FaqSlice
import React from "react";

const SectionHeader = ({ title, content }) => {

    return (
        <div className="section-header member">
            <h2 className="section-title mt-0 pt-3">{title}</h2>
            <p className="section-content member">{content}</p>
        </div>
    )
}

export default SectionHeader
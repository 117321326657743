import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import ResultSection from "../sections/ResultsSection/ResultsSection";

const ResultsSlice = ({ slice }) => {

    const contentData = slice.primary
    const allResultsItems = slice.items

    allResultsItems.sort(function(a,b) {
        let keyA = new Date(a.date)
        let keyB = new Date(b.date)

        if(keyA < keyB) return 1
        if(keyA > keyB) return -1
        return 0
    })

    return (

        <SectionLayout id="results" containerName="results">
            <SectionHeader title={contentData.title} content={contentData.content}/> 
            <ResultSection results={allResultsItems} />
        </SectionLayout>
    )
}

export default ResultsSlice
import React from "react";
import { Image } from "../Image/Image";
import { goToIcon } from "../../utils/imgLoader";

const LandingPageButton = ({ href, text }) => {

 return (
     <a className="btn button secondary m-0" rel="nofollow" href={href}>
         <Image 
         src={goToIcon} 
         alt="icon"
         loading={"eager"}
         fadeIn={false} 
         className="goToIcon"/>
         {text}
     </a>
 )   
}


export default LandingPageButton
import React, { useState } from "react";
import BodyClassName from "react-body-classname"
import { useMainHeaderData } from "../StaticDataHooks/useMainHeaderData";
import NavContainer from "./NavContainer";

const Header = () => {

    const headerData = useMainHeaderData()

    const data = headerData
    const btn = data.btn_text
    const headerItems = data.header_data

    const [hamburgerActive, setHamburgerActive] = useState(false)

    const hamburgerHandler = () => {
        setHamburgerActive(!hamburgerActive)
    }

    let hamburgerClsName = "hamburger my-auto m-4"
    let navMenuClsName = "nav-items desktop darkmenu"
    let bodyClsName = ""

    if (hamburgerActive){
        hamburgerClsName += " active"
        navMenuClsName += " active"
        bodyClsName += ""
    }

    return (
        <BodyClassName className={bodyClsName}>
        <header className="header">
            <div className="layout-container">
                <NavContainer 
                    src={false}
                    hamburgerClsName={hamburgerClsName}
                    hamburgerHandler={hamburgerHandler}spanClsn={"lightspan"}
                    navMenuClsName={navMenuClsName}
                    headerItems={headerItems}
                    btn={btn}
                />
            </div>
        </header>
        </BodyClassName>
    )
}


export default Header
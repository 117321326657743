import React from "react";
import { Image } from "../Image/Image";
import LandingPageButton from "../LandingPageButton/LandingPageButton";

const IntegrationItem = ({ item, content }) => {

    return (
        <div className="integrationItem">
            <div className="integrationItem__header">
                <div className="integration-container">
                    <Image 
                    src={item.icon} 
                    alt={item.icon.alt} 
                    loading={"eager"}
                    fadeIn={false}
                    className="integrationItem__header-img"/>
                </div>
                <h2 className="integrationItem__header-title">{item.title}</h2>
            </div>
            <div className="integrationItem__exchanges">
                <h5 className="integrationItem__exchanges-title">{item.header}</h5>
                <p className="integrationItem__exchanges-content">{item.exchanges}</p>
                <p className="integrationItem__exchanges-disclaimer">{item.disclaimer}</p>
            </div>
            <LandingPageButton href={item.link} text={content} />
        </div>
    )
}


export default IntegrationItem
import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import PricingContainer from "../components/PricingContainer/PricingContainer";

const PricingSlice = ({ slice }) => {

    const contentData = slice.primary
    const pricingItems = slice.items

    return (

        <SectionLayout id="pricing" containerName="results">
            <SectionHeader title={contentData.title} content={contentData.content}/>
            <PricingContainer pricingItems={pricingItems} contentData={contentData}/>
        </SectionLayout>
    )
}

export default PricingSlice
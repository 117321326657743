import React from "react";
import { Image } from "../Image/Image";
import { trendUp, trendDown } from "../../utils/imgLoader";

const TrendIcon = ({ trend }) => {

    let trendIcon

    if(trend >= 0){
        trendIcon = trendUp
    } else {
        trendIcon = trendDown
    }
    return (
        <div className="trendIcon">
            <Image 
                src={trendIcon} 
                loading={"eager"}
                fadeIn={false}
            />
        </div>
       
    )
}

export default TrendIcon
import React, { useState } from "react";



const FaqComponent = ({ questions }) => {

    const [activeIdx, setActiveIdx] = useState(-1)

    return (
       <div className="container faq-body">
            {questions.map((item, idx) => (
                <div className={idx === activeIdx ? "faq-item active" : "faq-item"}>
                    <h4 
                        className="faq-item__title position-relative"
                        onClick={() => {
                            if(idx === activeIdx) setActiveIdx(-1)
                            else setActiveIdx(idx)
                        }}
                        onKeyDown={() => {
                        if(idx === activeIdx) setActiveIdx(-1)
                            else setActiveIdx(idx)
                        }}
                        role="presentation"
                    >
                        {item.title}
                    </h4>
                    <p className="faq-item__content">{item.content}</p>
                </div>  
            ))}
        </div>
    )
}

export default FaqComponent
import { graphql, useStaticQuery } from "gatsby";

export const useMainHeaderData = () => {

    const data = useStaticQuery(graphql`
        query MainHeaderDataQuery {
            prismicMainheader {
                data {
                    header_data {
                        link
                        name
                    }
                    btn_text
                }
            }
        }
    `)
    const headerData = data.prismicMainheader.data
    return headerData
}
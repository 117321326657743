import React from "react";
import TrendIcon from "./TrendIcon";

const MetricWrap = ({ trendNumber, trendTitle }) => {

    const int = parseFloat(trendNumber)

    return (

        <div className="metric-wrap">
            <div className="metric-wrap__number">{trendTitle}</div>
            <div className={`metric-wrap__trend ${(int > 0) ? ('trendUp') : ('trendDown')}`}>
                <TrendIcon trend={int} />
                <div className={`trend`}>{trendNumber}</div>
            </div>
        </div>
    )

}


export default MetricWrap
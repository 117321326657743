import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import ExchangeItem from "../components/ExchangeItem/ExchangeItem";
import { useExchangesData } from "../components/StaticDataHooks/useExchangesData";

const ExchangeSlice = ({ slice }) => {

    const data = useExchangesData()

    return (
        <SectionLayout sectionName="exchanges">
        <div className="first-line d-flex">
                {data.map((item, idx) => (
                    <ExchangeItem 
                        item={item}
                        key={idx}
                    />
                ))}
            </div>
        </SectionLayout>
    )
}

export default ExchangeSlice